export function getBaseRoute() {
  switch (process.env.REACT_APP_ENV) {
    case "GP":
      return process.env.REACT_APP_ROUTE_BASE_GP
    default:
      return ""
  }
}

export function envGetGaCode() {
  return process.env.REACT_APP_GOOGLE_ANALYTIC_CODE
}
