//naviation
import { useNavigate } from "react-router-dom"

//Style
import style from "../../styles/default/header.module.css"

//Images
import ataLogo from "../../assets/images/ata/logo_with_tag.png"
import getPath from "../../helpers/routeHelper"

export default function Header() {
  const navigate = useNavigate()
  let path = ""

  switch (window.location.pathname) {
    case `/${getPath("calculator")}`:
      path = "calculator"
      break
    case `/${getPath("about")}`:
      path = "about"
      break
    default:
      path = "map"
  }

  function navigateToPath(path) {
    switch (path) {
      case "dis":
        navigate(getPath("DIS"))
        break
      case "calculator":
        navigate(getPath("calculator"))
        break
      case "about":
        navigate(getPath("about"))
        break
      default:
        navigate(getPath(""))
    }
  }

  return (
    <div className={style.mainContainer}>
      <div className={style.mainHeader}>
        <div className={style.ataLogoDiv}>
          <img src={ataLogo} alt="ATA" className={style.ataLogo} />
        </div>
        <div className={style.navigation}>
          <div className={style.navigationGrid}>
            <div className={style.optionsGridContainer}>
              <div
                className={`${style.optionItemMap} ${
                  path === "map" ? style.selectedPath : ""
                }`}
                onClick={() => navigateToPath("map")}
              >
                Digital Infrastructure Score (DIS)
              </div>
              <div
                className={`${style.optionItemCalculator} ${
                  path === "calculator" ? style.selectedPath : ""
                }`}
                onClick={() => navigateToPath("calculator")}
              >
                ESVA Calculator
              </div>
              <div
                className={`${style.optionItemAbout} ${
                  path === "about" ? style.selectedPath : ""
                }`}
                onClick={() => navigateToPath("about")}
              >
                About
              </div>
            </div>
          </div>
        </div>

        <div className={style.thsLogoDiv}></div>
      </div>
      <div className={style.headerborder}></div>
    </div>
  )
}
