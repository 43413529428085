import React, { useEffect, useState } from "react"
import "./App.css"
import getZipData from "./helpers/getZipData"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import getStateBoundsData from "./helpers/getStateBoundsData"

// Context
import { MainContext } from "./context/mainContext"

//Components
import Header from "./components/header/Header"
import RouteBuilder from "./config/RouteBuilder"
import Footer from "./components/footer/Footer"

//Helpers
import { envGetGaCode } from "./helpers/getEnvHelper"

//Google analytics
import { install } from "ga-gtag"

function App() {
  const nationalBounds = [
    [-125, 24],
    [-65, 50],
  ]

  const gaCode = envGetGaCode()

  //Set the Global Site Tag script in head. Also disable auto page view measurement for SPA
  install(gaCode, { send_page_view: false })

  const [zipData, setZipData] = useState(undefined)
  const [stateBoundsData, setStateBoundsData] = useState(undefined)
  const [stateOptions, setStateOptions] = useState(undefined)
  const [stateFilter, setStateFilter] = useState("")
  const [zipDataFiltered, setZipDataFiltered] = useState(undefined)
  const [stateBounds, setBounds] = useState(nationalBounds)
  const [defaultDataField] = useState("DigitalInfrastructureScore")
  const [selectedDataField, setSelectedDataField] = useState(defaultDataField)
  const [selectedZipData, setSelectedZipData] = useState(undefined)

  const [flyTo, setFlyTo] = useState(undefined)

  const changeSelection = (stateName) => {
    setStateFilter(stateName)

    if (!!stateBoundsData) {
      const newStateBounds = stateBoundsData.filter(
        (state) => state.state === stateName
      )

      if (!!newStateBounds && newStateBounds.length > 0) {
        setBounds([
          [newStateBounds[0].bounds[0][0], newStateBounds[0].bounds[0][1]],
          [newStateBounds[0].bounds[1][0], newStateBounds[0].bounds[1][1]],
        ])
      } else {
        setBounds(nationalBounds)
        console.error("Unable to determine state bounds")
      }
    }
  }

  const mainContextValue = {
    zipData,
    zipDataFiltered,
    stateBounds,
    nationalBounds,
    stateOptions,
    stateFilter,
    setStateFilter: changeSelection,
    defaultDataField,
    selectedDataField,
    setSelectedDataField,
    selectedZipData,
    setSelectedZipData,
    flyTo,
    setFlyTo,
  }

  useEffect(() => {
    setStateBoundsData(getStateBoundsData())
    getZipData(setZipData)
  }, [])

  useEffect(() => {
    if (!!zipData) {
      setStateOptions([...new Set(zipData.data.map((x) => x.state))])
    }
  }, [zipData])

  useEffect(() => {
    if (!!zipData) {
      if (!!stateFilter) {
        setZipDataFiltered(
          zipData?.data.filter(
            (x) => x.state === stateFilter && x.location_name !== ""
          )
        )
      } else {
        setZipDataFiltered(zipData.data)
      }
    }
  }, [stateFilter, zipData])

  return (
    <MainContext.Provider value={mainContextValue}>
      <div className="mainContainer">
        <div className="mainHeader">
          <Header />
        </div>
        <div className="mainBody">
          <RouteBuilder />
        </div>
        <div className="mainFooter">
          <Footer />
        </div>
      </div>
    </MainContext.Provider>
  )
}

export default App
