import { useCallback, useEffect, useState } from "react"

import {
  demographicCalculator,
  economicSocialCost,
  esvaEstimates,
  stakeHolderQualityLifeYearsSaved,
  stakeHolderSavings,
  stakeholderCost,
  telehealthCalculator,
} from "./calculatorFunctions"

//Styles
import styles from "../../styles/default/calculator.module.css"

//PrimeReact
import { Card } from "primereact/card"
import { InputNumber } from "primereact/inputnumber"
import { Accordion, AccordionTab } from "primereact/accordion"
import CalculatorChart from "./CalculatorChart"

export default function Calculator() {
  const [solutionImplementCost, setSolutionImplenentCost] = useState(500000)
  const [pmpyUtilizationCost, setPmpyUtilizationCost] = useState(500)
  const [totalPopulation, setTotalPopulation] = useState(759876)
  const [percentageImpactedByDisease, setPercImpactedByDisease] = useState(3.7)
  const [percentageNoInternet, setPercentageNoIntermet] = useState(10)
  const [percentageSlowConnection, setPercentageSlowConnection] = useState(30)
  const [percentageNeedDataSubsidy, setPercentageNeedDataSubsidy] = useState(10)
  const [economicBurdenPerCapita, setEconomicBurdenPerCapita] = useState(69814)
  const [qualityLifeYearsPerCapita, setQualityLifeYearsPerCapita] = useState(5)

  const [calculations, setCalculations] = useState({})

  const doCalculation = useCallback(() => {
    let result = {}

    //Demographic
    const demographicData = demographicCalculator(
      totalPopulation,
      percentageImpactedByDisease / 100,
      percentageNoInternet / 100,
      percentageSlowConnection / 100,
      percentageNeedDataSubsidy / 100
    )

    const telehealthData = telehealthCalculator(
      demographicData,
      solutionImplementCost,
      pmpyUtilizationCost
    )

    result.economicCost = economicSocialCost(
      pmpyUtilizationCost, //C15
      solutionImplementCost, //C13
      percentageNeedDataSubsidy / 100, //C29
      economicBurdenPerCapita, //C34
      qualityLifeYearsPerCapita, //C36
      telehealthData,
      demographicData
    )
    result.stakeholderCost = stakeholderCost(result.economicCost)
    result.stakeHolderSavings = stakeHolderSavings(result.economicCost)
    result.stakeholderQualityYearsSavings = stakeHolderQualityLifeYearsSaved(
      result.economicCost
    )
    result.telehealth = telehealthData
    result.demographic = demographicData
    result.esvaEstimates = esvaEstimates(
      economicBurdenPerCapita,
      qualityLifeYearsPerCapita
    )

    setCalculations(result)
  }, [
    solutionImplementCost,
    pmpyUtilizationCost,
    totalPopulation,
    percentageImpactedByDisease,
    percentageNoInternet,
    percentageSlowConnection,
    percentageNeedDataSubsidy,
    economicBurdenPerCapita,
    qualityLifeYearsPerCapita,
  ])

  useEffect(() => {
    doCalculation()
  }, [doCalculation])

  const GetCard = (category, name) => {
    let customStyleClass = ""

    switch (name) {
      case "stakeholderCost":
        customStyleClass = styles.stakeHolderCostCard
        break
      case "stakeHolderSavings":
        customStyleClass = styles.stakeHolderSavingsCard
        break
      case "stakeholderQualityYearsSavings":
        customStyleClass = styles.stakeHolderQalyCard
        break
      default:
        break
    }

    const getCardValue = (object) => {
      let value = object.value

      if (!!object.decimals) {
        value = (+value).toFixed(object.decimals)
      }

      value = (+value).toLocaleString()

      if (object.unitValueInFront) {
        value = `${object.unitValue} ${value}`
      } else {
        value = `${value} ${object.unitValue}`
      }

      return value
    }

    return (
      <Card
        title={category.label}
        className={`${styles.card} ${customStyleClass}`}
      >
        {Object.keys(category.values).map((x, index) => {
          return (
            <div
              className={`${
                !!category.values[x].isCalculation
                  ? styles.cardItemCalculated
                  : styles.cardItemInput
              } `}
              key={index}
            >
              {category.values[x].label} : {getCardValue(category.values[x])}
            </div>
          )
        })}
      </Card>
    )
  }

  return (
    <div>
      <div>
        <Accordion activeIndex={0}>
          <AccordionTab
            header="ESVA Calculator"
            headerClassName={styles.accordionHeader}
          >
            <div className={styles.calculatorInputs}>
              <div className={styles.calculatorInputGrid}>
                <div className={styles.calculatorItemInputText}>
                  <p>
                    The Economic Social and Value Added (ESVA) calculator offers
                    insight into the various costs required to close digital
                    disparities and how those costs should be allocated across
                    payers, providers, and governments based on the economic
                    value these stakeholders will derive from addressing the
                    underlying disparity and the correlated improvements to
                    health.
                  </p>
                  <p>
                    The ESVA calculator is interactive allowing you to adjust
                    several input factors to identify the overall cost required
                    to underwrite the infrastructure and platform needs for
                    specific, targeted investments to improve the overall
                    availability to/of resources as detailed in output below.
                    Reference the About section for definitions and default
                    settings on each available input and calculated output of
                    interest.
                  </p>
                </div>
                <div
                  className={`${styles.calculatorItemTelehealthHeader} ${styles.calculatorHeaderItem}`}
                >
                  Telehealth Intervention Inputs
                </div>
                <div className={styles.calculatorItemSolutionCostTItle}>
                  Solution Implementation Cost
                </div>
                <div className={styles.calculatorItemSolutionCost}>
                  <InputNumber
                    value={solutionImplementCost}
                    onChange={(e) => {
                      setSolutionImplenentCost(e.value)
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                </div>
                <div className={styles.calculatorItemPmptyTitle}>
                  PMPY Utilization Cost
                </div>
                <div className={styles.calculatorItemPmpty}>
                  <InputNumber
                    value={pmpyUtilizationCost}
                    onChange={(e) => {
                      setPmpyUtilizationCost(e.value)
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                </div>
                <div
                  className={`${styles.calculatorItemDemographichHeader} ${styles.calculatorHeaderItem}`}
                >
                  Demographic Inputs
                </div>
                <div className={styles.calculatorItemTotalPopulationTitle}>
                  Total Population
                </div>
                <div className={styles.calculatorItemTotalPopulation}>
                  <InputNumber
                    value={totalPopulation}
                    onChange={(e) => {
                      setTotalPopulation(e.value)
                    }}
                    locale="en-US"
                  />
                </div>
                <div className={styles.calculatorItemDiseaseImpactTitle}>
                  % of Population Impacted by Disease/Social Burden
                </div>
                <div className={styles.calculatorItemDiseaseImpact}>
                  <InputNumber
                    value={percentageImpactedByDisease}
                    onChange={(e) => {
                      setPercImpactedByDisease(e.value)
                    }}
                    suffix="%"
                  />
                </div>
                <div className={styles.calculatorItemNoInternetTitle}>
                  % of Population with No Internet Connection
                </div>
                <div className={styles.calculatorItemNoInternet}>
                  <InputNumber
                    value={percentageNoInternet}
                    onChange={(e) => {
                      setPercentageNoIntermet(e.value)
                    }}
                    suffix="%"
                  />
                </div>
                <div className={styles.calculatorItemSlowConnectionTitle}>
                  % of Population with Slow Connection
                </div>
                <div className={styles.calculatorItemSlowConnection}>
                  <InputNumber
                    value={percentageSlowConnection}
                    onChange={(e) => {
                      setPercentageSlowConnection(e.value)
                    }}
                    suffix="%"
                  />
                </div>
                <div className={styles.calculatorItemDataSubsidyTitle}>
                  % of Population in Need of Data Subsidies
                </div>
                <div className={styles.calculatorItemDataSubsidy}>
                  <InputNumber
                    value={percentageNeedDataSubsidy}
                    onChange={(e) => {
                      setPercentageNeedDataSubsidy(e.value)
                    }}
                    suffix="%"
                  />
                </div>
                <div
                  className={`${styles.calculatorItemEsvaHeader} ${styles.calculatorHeaderItem}`}
                >
                  ESVA Estimates
                </div>
                <div className={styles.calculatorItemEconomicBurdenTitle}>
                  Estimated Economic Burden per Capita
                </div>
                <div className={styles.calculatorItemEconomicBurden}>
                  <InputNumber
                    value={economicBurdenPerCapita}
                    onChange={(e) => {
                      setEconomicBurdenPerCapita(e.value)
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                </div>
                <div className={styles.calculatorItemQalyTitle}>
                  Quality Adjusted Life Years per Capita
                </div>
                <div className={styles.calculatorItemQaly}>
                  <InputNumber
                    value={percentageNeedDataSubsidy}
                    onChange={(e) => {
                      setQualityLifeYearsPerCapita(e.value)
                    }}
                    suffix=" years"
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>

      <div className={styles.calculatorContainer}>
        <div className={styles.cardContainer}>
          <CalculatorChart
            stakeholderCost={calculations?.stakeholderCost}
            stakeholderSavings={calculations?.stakeHolderSavings}
            stakeHolderQualityLifeYearsSaved={
              calculations?.stakeholderQualityYearsSavings
            }
          />
          <div className={styles.infoCard}>
            <p>
              Calculations below identify the total cost and prospective sources
              of capital and financing for underwriting the tools and resources
              necessary in a community to move health or social disparities.
            </p>
          </div>
          {Object.keys(calculations).map((x, index) =>
            GetCard(calculations[x], x)
          )}
        </div>
      </div>
    </div>
  )
}
