import React from "react"
import { Route, Routes } from "react-router-dom"

//Routes:
import About from "../pages/about/About"
import Calculator from "../pages/calculator/Calculator"
import MainMap from "../pages/mainMap/MainMap"
import getPath from "../helpers/routeHelper"
import RouteChangeTracker from "../components/GoogleAnalytics/RouteChangeTracker"

export default function RouteBuilder() {
  const router = [
    { path: getPath("/"), element: <MainMap /> },
    { path: getPath(""), element: <MainMap /> },
    { path: getPath("DIS"), element: <MainMap /> },
    { path: getPath("calculator"), element: <Calculator /> },
    { path: getPath("about"), element: <About /> },
  ]

  return (
    <>
      <RouteChangeTracker />
      <Routes>
        {router.map((x, i) => (
          <Route key={i} path={x.path} element={x.element} />
        ))}
        <Route path="*" component={<MainMap />} />
      </Routes>
    </>
  )
}
