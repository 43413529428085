import { useContext, useState } from "react"

//Context
import { MainContext } from "../../context/mainContext"

//Styles
import AtaMap from "../../components/map/AtaMap"

//PrimeReact
import { InputText } from "primereact/inputtext"

export default function MainMap() {
  const mainContext = useContext(MainContext)

  const [showBoundingBox] = useState(false)

  return (
    <>
      {!!showBoundingBox && !!mainContext.stateBounds ? (
        <>
          <InputText
            value={mainContext.stateBounds[0][0]}
            onChange={(e) =>
              mainContext.setBounds([
                [e.target.value, mainContext.stateBounds[0][1]],
                [mainContext.stateBounds[1][0], mainContext.stateBounds[1][1]],
              ])
            }
          />
          <InputText
            value={mainContext.stateBounds[0][1]}
            onChange={(e) =>
              mainContext.setBounds([
                [mainContext.stateBounds[0][0], e.target.value],
                [mainContext.stateBounds[1][0], mainContext.stateBounds[1][1]],
              ])
            }
          />
          ::
          <InputText
            value={mainContext.stateBounds[1][0]}
            onChange={(e) =>
              mainContext.setBounds([
                [mainContext.stateBounds[0][0], mainContext.stateBounds[0][1]],
                [e.target.value, mainContext.stateBounds[1][1]],
              ])
            }
          />
          <InputText
            value={mainContext.stateBounds[1][1]}
            onChange={(e) =>
              mainContext.setBounds([
                [mainContext.stateBounds[0][0], mainContext.stateBounds[0][1]],
                [mainContext.stateBounds[1][0], e.target.value],
              ])
            }
          />
        </>
      ) : null}
      <AtaMap showBoundingBox={showBoundingBox} />
    </>
  )
}
