import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react"

//AtaMap components
import { popupGetBase, popupHtml } from "./AtaMapPopup"
import AtaMapPanel from "./AtaMapPanel"
import MapboxChoropleth from "./choroplethCreator"
import style from "../../styles/default/ataMap.module.css"

//Mapbox
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

// Context
import { MainContext } from "../../context/mainContext"
import AtaMapLegend from "./AtaMapLegend"

export default function AtaMap({ showBoundingBox }) {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

  const {
    zipData,
    zipDataFiltered,
    stateFilter,
    stateBounds,
    selectedDataField,
    selectedZipData,
    flyTo,
    setFlyTo,
  } = useContext(MainContext)

  const [heatMapObj, setHeatMapObj] = useState(undefined)
  const [cachedFeaturesState, setCachedFeaturesState] = useState(undefined)
  const [cachedFeatures, setCachedFeatures] = useState([])

  const mapContainer = useRef(null)
  const map = useRef(null)
  const selectedZipDataRef = useRef(undefined)
  const selectedZipFeatureRef = useRef(undefined)
  const hoveredZipRef = useRef(undefined)
  const showSelectedRef = useRef(undefined)
  const popup = useRef(popupGetBase())

  const mapDataId = process.env.REACT_APP_MAPBOX_TILE_SET_FIELD_ID
  const mapSourceLayerName =
    process.env.REACT_APP_MAPBOX_TILE_SET_SOURCE_LAYER_NAME
  const dataToMapDataId = "zip_code"

  const heatmapLayerId = "choropleth"
  const heatMapColors = ["#FCC249", "#9C4713"]
  const heatMapSelectedLineColor = "#315499"
  const defaultCenter = [-94.24, 38.5] //[longitude, latitude]
  const defaultZoom = 4

  //If the data variable changes, set the map and then add the choropleth
  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current, // container ID
        style: process.env.REACT_APP_MAPBOX_STYLE_URL, // style URL
        center: defaultCenter, // starting position [lng, lat]
        zoom: defaultZoom, // starting zoom
        minZoom: defaultZoom,
        //logoPosition: "bottom-right", //Move logo from bottom-left to top-left , top-right, bottom-right
      })

      if (!!zipDataFiltered && zipDataFiltered.length > 0) {
        map.current.on("load", function () {
          addChoroplethToMap(zipDataFiltered)
        })
      }
    } else {
      if (!!zipDataFiltered && zipDataFiltered.length > 0) {
        addChoroplethToMap(zipDataFiltered)
      } else {
        console.log("No data!")
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipDataFiltered, selectedDataField])

  const findFeature = (DataId) => {
    if (!!DataId && map.current.getLayer(heatmapLayerId)) {
      let features = map.current.queryRenderedFeatures({
        layers: [heatmapLayerId],
        filter: ["==", mapDataId, DataId],
      })
      /*       let features = map.current.querySourceFeatures(heatmapLayerId, {
        sourceLayer: [mapSourceLayerName],
        filter: ["==", mapDataId, DataId],
      }) */

      let feature = undefined
      if (features.length > 0) {
        features = features.filter(
          (feature) => feature.properties[mapDataId] === DataId
        )
        if (features.length > 0) {
          feature = features[0]
        }
      }

      return feature
    }
  }

  const setSelectedFeatureBorder = useCallback(() => {
    if (!selectedZipData && !!selectedZipDataRef.current) {
      selectedZipDataRef.current = undefined
      showSelectedRef.current = false
    }

    if (!!map.current) {
      if (!!selectedZipData || !!showSelectedRef.current) {
        if (map.current.getLayer(heatmapLayerId)) {
          //Check feature cache
          let cachedStateFeatures = cachedFeatures
          if (
            cachedFeaturesState !== selectedZipData.state ||
            cachedFeatures.length === 0
          ) {
            cachedStateFeatures = map.current.queryRenderedFeatures({
              layers: [heatmapLayerId],
            })

            setCachedFeatures(cachedStateFeatures)
            setCachedFeaturesState(selectedZipData.state)
          }

          let newFeature = cachedStateFeatures.find(
            (feature) =>
              feature.properties[mapDataId] === selectedZipData[dataToMapDataId]
          )

          if (!!newFeature && !newFeature.properties.isSelected) {
            //unselect previously selected Id
            if (!!newFeature && !!selectedZipFeatureRef.current) {
              map.current.setFeatureState(
                {
                  source: heatmapLayerId,
                  sourceLayer: mapSourceLayerName,
                  id: selectedZipFeatureRef.current.id,
                },
                { isSelected: false }
              )
              selectedZipFeatureRef.current = undefined
              selectedZipDataRef.current = undefined
            }

            //select new selected Id
            if (!!newFeature || newFeature === 0) {
              map.current.setFeatureState(
                {
                  source: heatmapLayerId,
                  sourceLayer: mapSourceLayerName,
                  id: newFeature.id,
                },
                { isSelected: true }
              )

              //Get coordinates
              let bounds = {},
                longitude,
                latitude

              const processCoordinates = (coordinates) => {
                for (let j = 0; j < coordinates.length; j++) {
                  if (coordinates[j][0].length > 1) {
                    processCoordinates(coordinates[j])
                  } else {
                    longitude = coordinates[j][0]
                    latitude = coordinates[j][1]
                    bounds.xMin =
                      bounds.xMin < longitude ? bounds.xMin : longitude
                    bounds.xMax =
                      bounds.xMax > longitude ? bounds.xMax : longitude
                    bounds.yMin =
                      bounds.yMin < latitude ? bounds.yMin : latitude
                    bounds.yMax =
                      bounds.yMax > latitude ? bounds.yMax : latitude
                  }
                }
              }

              processCoordinates(newFeature.geometry.coordinates[0])

              const boundingBox = new mapboxgl.LngLatBounds(
                new mapboxgl.LngLat(bounds.xMin, bounds.yMin),
                new mapboxgl.LngLat(bounds.xMax, bounds.yMax)
              )
              const featureCenter = boundingBox.getCenter()

              setFlyTo(featureCenter)

              selectedZipFeatureRef.current = newFeature
              selectedZipDataRef.current = selectedZipData
            }
          }
        }
      } else if (!selectedZipData && !!selectedZipFeatureRef.current) {
        map.current.setFeatureState(
          {
            source: heatmapLayerId,
            sourceLayer: mapSourceLayerName,
            id: selectedZipFeatureRef.current.id,
          },
          { isSelected: false }
        )
        selectedZipFeatureRef.current = undefined
        selectedZipDataRef.current = undefined
        fitToBounds()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZipData, map.current, selectedDataField])

  useEffect(() => {
    setSelectedFeatureBorder()
  }, [setSelectedFeatureBorder])

  useEffect(() => {
    if (!!map.current && !!flyTo) {
      map.current.flyTo({ center: flyTo, zoom: 9 })
    }
  }, [flyTo])

  //Focus the map on this new location
  const fitToBounds = useCallback(() => {
    const defaultBoundingBox = [
      [-120, 24],
      [-40, 55],
    ] // Array of 2x combinations of [longitude, latitude]

    if (map.current) {
      map.current.fitBounds(!!stateBounds ? stateBounds : defaultBoundingBox, {
        maxZoom: 10,
      })

      if (showBoundingBox && map.current.isStyleLoaded()) {
        const data = {
          type: "FeatureCollection",
          features: [
            {
              // feature for Mapbox DC
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [stateBounds[0][0], stateBounds[0][1]],
              },
              properties: {
                title: "Mapbox DC",
              },
            },
            {
              // feature for Mapbox SF
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [stateBounds[1][0], stateBounds[1][1]],
              },
              properties: {
                title: "Mapbox SF",
              },
            },
          ],
        }

        if (!map.current.getLayer("bb")) {
          // Add an image to use as a custom marker
          map.current.loadImage(
            "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            (error, image) => {
              if (error) throw error
              map.current.addImage("custom-marker", image)

              // Add a GeoJSON source with 2 points
              map.current.addSource("bbpoints", {
                type: "geojson",
                data: data,
              })

              // Add a symbol layer
              map.current.addLayer({
                id: "bb",
                type: "symbol",
                source: "bbpoints",
                layout: {
                  "icon-image": "custom-marker",
                  // get the title name from the source's "title" property
                  "text-field": ["get", "title"],
                  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  "text-offset": [0, 1.25],
                  "text-anchor": "top",
                },
              })
            }
          )
        } else {
          let src = map.current.getSource("bbpoints")
          src.setData(data)
        }
      }
    }
  }, [stateBounds, showBoundingBox])

  useEffect(() => {
    fitToBounds()
  }, [fitToBounds])

  //Create the Choropleth object
  const getChoropleth = (data) => {
    return new MapboxChoropleth({
      tableRows: data,
      tableNumericField: selectedDataField,
      tableIdField: dataToMapDataId,
      fieldDataType: "",
      geometryType: "tileset",
      geometryUrl: process.env.REACT_APP_MAPBOX_TILE_SET_URL,
      geometryIdField: mapDataId,
      sourceLayer: mapSourceLayerName,
      binCount: 9,
      colorScheme: heatMapColors,
      //before: "state-label",
      lineLayerWidthHover: 2,
      lineLayerColorSelected: heatMapSelectedLineColor,
      debug: false,
    })
  }

  const onMapStyleAndSourceLoaded = (fn) => {
    // It seems to be so hard to reliably add a layer without hitting a 'style not ready' error.
    if (map.current.isStyleLoaded()) {
      if (!map.current.getSource(heatmapLayerId)) {
        const nextFn = () =>
          window.setTimeout((fn) => {
            onMapStyleAndSourceLoaded(fn)
          }, 10)
        nextFn()
      } else {
        const nextFn = () => window.setTimeout(fn, 0)
        nextFn()
      }
    } else {
      map.current.once("style.load", (fn) => {
        onMapStyleAndSourceLoaded(fn)
      })
    }
  }

  const checkChoropleth = () => {
    //console.log(data)
    showSelectedArea()
  }

  const showSelectedArea = () => {
    if (!!showSelectedRef.current) {
      if (!!selectedZipDataRef.current && !!selectedZipFeatureRef.current) {
        let feature = findFeature(
          selectedZipFeatureRef.current.properties[mapDataId]
        )
        if (!!feature) {
          if (!feature.state.isSelected) {
            map.current.setFeatureState(
              {
                source: heatmapLayerId,
                sourceLayer: mapSourceLayerName,
                id: feature.id,
              },
              { isSelected: true }
            )
          }
          showSelectedRef.current = false
        }
      } else if (!selectedZipDataRef.current) {
        showSelectedRef.current = false
      }
    }
  }

  const addChoroplethToMap = (data) => {
    try {
      const heatMap = getChoropleth(data)
      heatMap.addTo(map.current)
      heatMap.table.then(() => {
        setHeatMapObj(heatMap)

        if (!!stateFilter) {
          // When the user moves their mouse over the state-fill layer, we'll update the
          // feature state for the feature under the mouse.
          map.current.on("mousemove", heatmapLayerId, (e) => {
            if (e.features.length > 0) {
              if (!!hoveredZipRef.current || hoveredZipRef.current === 0) {
                map.current.setFeatureState(
                  {
                    source: heatmapLayerId,
                    sourceLayer: mapSourceLayerName,
                    id: hoveredZipRef.current,
                  },
                  { hover: false }
                )
              }

              const dataSet = zipData.data.filter(
                (filter) =>
                  filter.state === stateFilter &&
                  filter[dataToMapDataId] ===
                    e.features[0].properties[mapDataId]
              )
              if (dataSet.length > 0) {
                hoveredZipRef.current = e.features[0].id

                map.current.setFeatureState(
                  {
                    source: heatmapLayerId,
                    sourceLayer: mapSourceLayerName,
                    id: e.features[0].id,
                  },
                  { hover: true }
                )

                popup.current
                  .setLngLat({ lat: e.lngLat.lat, lng: e.lngLat.lng })
                  .setHTML(popupHtml(dataSet[0], selectedDataField))
                  .addTo(map.current)
              } else {
                popup.current.remove()
              }
            }
          })

          // When the mouse leaves the state-fill layer, update the feature state of the
          // previously hovered feature.
          map.current.on("mouseleave", heatmapLayerId, () => {
            if (!!hoveredZipRef.current) {
              map.current.setFeatureState(
                {
                  source: heatmapLayerId,
                  sourceLayer: mapSourceLayerName,
                  id: hoveredZipRef.current,
                },
                { hover: false }
              )

              hoveredZipRef.current = undefined
              popup.current.remove()
            }
          })
        }

        /*         map.current.on("render", heatmapLayerId, () => {
          showSelectedArea()
        }) */

        map.current.on("idle", heatmapLayerId, () => {
          showSelectedArea()
        })

        /*         map.current.on("moveend", () => {
          showSelectedArea()
        }) */

        onMapStyleAndSourceLoaded(() => {
          checkChoropleth()
          showSelectedRef.current = true
          showSelectedArea()
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={style.mainContainer}>
      <div
        ref={mapContainer}
        id="map"
        style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}
      />

      <AtaMapPanel />
      <AtaMapLegend choroplethObject={heatMapObj} />
    </div>
  )
}
