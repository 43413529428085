import { getBaseRoute } from "./getEnvHelper"

export default function getPath(path) {
  let baseRoute = getBaseRoute()

  if (!!baseRoute) {
    baseRoute = baseRoute + "/"
  }

  return `${baseRoute}${path}`
}
