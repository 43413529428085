import React, { useContext, useEffect, useRef, useState } from "react"

//Data
import fieldOptions from "../../assets/data/variableMap.json"

//Styles
import styles from "../../styles/default/mapPanel.module.css"

// Context
import { MainContext } from "../../context/mainContext"

//PrimeReact
import { Dropdown } from "primereact/dropdown"
import { getDisplayValue } from "../../helpers/zipDataDisplayHelper"

//Google analytics
import { gtag } from "ga-gtag"

export default function AtaMapPanel() {
  const {
    zipData,
    stateFilter,
    setStateFilter,
    stateOptions,
    defaultDataField,
    selectedDataField,
    setSelectedDataField,
    selectedZipData,
    setSelectedZipData,
  } = useContext(MainContext)

  const [selectedField, setSelectedField] = useState({})
  const [zipOptions, setZipOptions] = useState([])
  const [categoryOptionsFullList, setCategoryOptionsFullList] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const gtagReportedData = useRef()

  useEffect(() => {
    if (
      !gtagReportedData.current ||
      gtagReportedData.current.state !== stateFilter ||
      gtagReportedData.current.county !== selectedZipData?.county ||
      gtagReportedData.current.zip !== selectedZipData?.zip_code ||
      gtagReportedData.current.dataCategory !== selectedField?.category ||
      gtagReportedData.current.dataCategoryItem !== selectedField?.categoryItem
    ) {
      const newSelection = {
        state: stateFilter,
        county: selectedZipData?.county,
        zip: selectedZipData?.zip_code,
        dataCategory: selectedField?.category,
        dataCategoryItem: selectedField?.categoryItem,
      }

      gtagReportedData.current = newSelection
      gtag("event", "data_selection", newSelection)
    }
  }, [stateFilter, selectedField, selectedZipData])

  useEffect(() => {
    if (!!stateFilter) {
      setCategoryOptions(categoryOptionsFullList)
    } else {
      const excludeCategories = ["Community Demographics"]
      setCategoryOptions(
        categoryOptionsFullList.filter(
          (cat) => !excludeCategories.some((option) => option === cat.category)
        )
      )

      if (
        excludeCategories.some((option) => option === selectedField.category)
      ) {
        var defaultField = fieldOptions.find(
          (option) => option.fieldName === defaultDataField
        )
        setSelectedDataField(defaultDataField)
        setSelectedField(defaultField)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoryOptionsFullList,
    defaultDataField,
    selectedField.category,
    stateFilter,
  ])

  useEffect(() => {
    if (!!fieldOptions) {
      //Get list of categories
      const cats = [
        ...new Set(
          fieldOptions.map((option) => {
            return option.category
          })
        ),
      ].filter((category) => !!category)

      let groupedCat = []

      cats.forEach((category) => {
        groupedCat.push({
          category: category,
          items: fieldOptions.filter((option) => option.category === category),
        })
      })

      setCategoryOptionsFullList(groupedCat)

      //Get currently selected data row
      const currentOption = fieldOptions.filter(
        (option) => option.fieldName === selectedDataField
      )

      if (currentOption.length > 0) {
        setSelectedField(currentOption[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldOptions])

  const getZipOptions = (stateName) => {
    const filteredZips = zipData?.data.filter(
      (zip) => zip.state === stateName && !!zip.location_name
    )

    setZipOptions(filteredZips)
    setSelectedZipData(undefined)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.text}>State:</div>
      <Dropdown
        value={stateFilter}
        onChange={(x) => {
          setStateFilter(x.value)
          getZipOptions(x.value)
        }}
        options={stateOptions}
        disabled={!stateOptions /*  || disableState */}
        placeholder={"Select a state to view data"}
        className={styles.dropDown}
        filter
        showClear
      />
      <>
        <div className={styles.text}>Zip code:</div>
        <Dropdown
          value={selectedZipData}
          onChange={(x) => {
            setSelectedZipData(x.value)
          }}
          options={zipOptions}
          disabled={!fieldOptions || !stateFilter}
          optionLabel="location_name"
          placeholder="Select a zip code"
          className={styles.dropDown}
          filter
          showClear
        />
        <div className={styles.text}>Data:</div>
        <Dropdown
          value={selectedField}
          onChange={(x) => {
            setSelectedField(x.value)
            setSelectedDataField(x.value.fieldName)
          }}
          options={categoryOptions}
          optionGroupLabel="category"
          optionGroupChildren="items"
          disabled={!fieldOptions || !categoryOptions}
          optionLabel="categoryItem"
          className={styles.dropDown}
          filter
          panelClassName={styles.dropDownPanel}
        />

        <div className={`${styles.text} ${styles.headerText}`}>
          Description:
        </div>
        <div className={styles.text}>
          {!!selectedField.description
            ? selectedField.description
            : selectedField.displayName}
        </div>

        {!!selectedZipData ? (
          <>
            <div className={`${styles.text} ${styles.headerText}`}>
              Selected area value:
            </div>
            <div className={`${styles.text}`}>
              {!!selectedZipData && !!selectedZipData[selectedDataField]
                ? getDisplayValue(
                    selectedZipData[selectedDataField],
                    selectedField.valueUnit,
                    selectedField.valueUnitPosition,
                    selectedField.decimalPrecision
                  )
                : "Not available"}
            </div>
          </>
        ) : null}
        {!!selectedField && selectedField.source === "BroadbandNow" ? (
          <div className={`${styles.source}`}>
            <span className={styles.textTitle}>Source: </span>
            <a
              href="https://broadbandnow.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              BroadbandNow
            </a>
          </div>
        ) : null}
      </>
    </div>
  )
}
