//Styles
import style from "../../styles/default/about.module.css"

//Download asset
import downloadDocument from "../../assets/files/ATA-disparities-advisory-group-paper-v2.pdf"

//Google analytics
import { gtag } from "ga-gtag"

export default function About() {
  return (
    <div className={style.container}>
      <div className={style.contentBlock}>
        <div className={style.title}>
          Engaging Care Teams to Eliminate Health Disparities Via Telehealth
        </div>
        <div className={style.body}>
          <p>
            In 2021 the American Telemedicine Association (ATA) convened the
            CEO’s Advisory Group on Using Telehealth to Eliminate Healthcare
            Disparities and Inequities (Disparities Advisory Group). This group
            is constituted of national leaders across health care and technology
            domains who bring a wide range of experience and strong interest in
            practical approaches to leverage telehealth to address this critical
            issue.
          </p>
          <p>
            Meeting monthly since its inception, the (DAG) focuses on
            identifying areas where telehealth can serve as a means of improving
            access to affordable health-related services through digital
            modalities, and how telehealth can have a material impact on
            disparities and inequities in local communities.
          </p>
          <p>
            The group has held conferences and webinars in addition to releasing
            select publications focused on the role telehealth can play in
            eliminating disparities. Most recently, the DAG released a set of
            tools to support the development and implementation of disease
            and/or social interventions that will benefit all stakeholders
            working to eliminate health care disparities.
          </p>
        </div>
        <div className={style.download}>
          <a
            href={downloadDocument}
            download={"ATA-disparities-advisory-group-paper-v2.pdf"}
            target="_blank"
            rel="noreferrer"
          >
            <button
              className={style.downloadButton}
              type="button"
              onClick={() => {
                gtag("event", "report", {
                  action: "Downloaded report",
                })
              }}
            >
              Download the Complete Toolkit
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}
