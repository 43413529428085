import React from "react"
import { renderToString } from "react-dom/server"

//mapbox
import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax

//AtaMap components
import popupStyle from "../../styles/default/choropleth.popup.module.css"

//Data
import fieldOptions from "../../assets/data/variableMap.json"
import { getDisplayValue } from "../../helpers/zipDataDisplayHelper"

export function popupGetBase() {
  return new mapboxgl.Popup({
    closeButton: false,
    className: popupStyle.mainBody,
  })
}

export function popupHtml(zipData, selectedDataField) {
  return renderToString(
    <PopupZip zipData={zipData} selectedDataField={selectedDataField} />
  )
}

export function PopupZip({ zipData, selectedDataField }) {
  if (!zipData) {
    return <></>
  }

  const selectionOptionProfile = fieldOptions.filter(
    (option) => option.fieldName === selectedDataField
  )

  let value = zipData[selectedDataField]
  if (value === "NULL") {
    value = "Not available"
  } else if (selectionOptionProfile.length > 0) {
    const categoryItem = selectionOptionProfile[0]
    value = getDisplayValue(
      value,
      categoryItem.valueUnit,
      categoryItem.valueUnitPosition,
      categoryItem.decimalPrecision
    )
  }

  return (
    <div>
      <div>County: {zipData.county}</div>
      <div>Location: {zipData.location_name}</div>
      <div>Value: {value || "Not available"}</div>
    </div>
  )
}
