//Styles
import style from "../../styles/default/footer.module.css"

//Images
import thsLogo from "../../assets/images/ths/THS_logo_white.png"

export default function Footer() {
  return (
    <div className={style.mainFooter}>
      <div className={style.text}>
        © American Telemedicine Association 2023. Version 1.1. Powered by Third
        Horizon Analytics
      </div>
      <div className={style.thsLogo}>
        <img src={thsLogo} alt="THS" className={style.thsLogo} />
      </div>
    </div>
  )
}
