const getCalculationObject = (
  label,
  value,
  decimals,
  unitValue,
  unitValueFront,
  isCalculation
) => {
  if (unitValue === undefined) {
    unitValue = "$"
  }

  if (unitValueFront === undefined) {
    unitValueFront = true
  }

  if (decimals === undefined) {
    decimals = 2
  }

  if (isCalculation === undefined) {
    isCalculation = true
  }

  return {
    label: label,
    value: value,
    decimals: decimals,
    unitValue: unitValue,
    unitValueInFront: unitValueFront,
    isCalculation: isCalculation,
  }
}

const getInputObject = (label, value, unitValue, unitValueFront) => {
  return getCalculationObject(label, value, 2, unitValue, unitValueFront, false)
}

const getMainObject = (label) => {
  return { label: label, values: {} }
}

export function demographicCalculator(
  totalPopulation,
  percentageImpactedByDisease,
  percentageNoInternet,
  percentageSlowConnection,
  percentageNeedDataSubsidy
) {
  let demographic = getMainObject("Demographics")

  demographic.values.totalPopulation = getInputObject(
    "Total Population",
    totalPopulation,
    ""
  )
  demographic.values.percentageImpactedByDisease = getInputObject(
    "% of Population Impacted by Disease/Social Burden",
    percentageImpactedByDisease * 100,
    "%",
    false
  )
  demographic.values.percentageNoInternet = getInputObject(
    "% of Population with No Internet Connection",
    percentageNoInternet * 100,
    "%",
    false
  )
  demographic.values.percentageSlowConnection = getInputObject(
    "% of Population with Slow Connection",
    percentageSlowConnection * 100,
    "%",
    false
  )
  demographic.values.percentageNeedDataSubsidy = getInputObject(
    "% of Population in Need of Data Subsidies",
    percentageNeedDataSubsidy * 100,
    "%",
    false
  )

  let formula =
    totalPopulation *
    percentageImpactedByDisease *
    (percentageNoInternet + percentageSlowConnection)
  demographic.values.addressablePopulation = getCalculationObject(
    "Addressable Population",
    formula,
    2,
    ""
  )

  return demographic
}

export function telehealthCalculator(
  demographics,
  solutionImplementCost,
  pmpyUtilizationCost
) {
  const coreCost = process.env.REACT_APP_CALC_CORE_COST

  let telehealth = getMainObject("Telehealth Intervention")

  telehealth.values.solutionImplementCost = getInputObject(
    "Solution Implementation Cost",
    solutionImplementCost
  )
  telehealth.values.pmpyUtilizationCost = getInputObject(
    "PMPY Utilization Cost",
    pmpyUtilizationCost
  )

  let formula = demographics.values.addressablePopulation.value * coreCost
  telehealth.values.FixedDigitalInfrastructureCost = getCalculationObject(
    "Fixed Digital Infrastructure Installation Cost",
    formula
  )

  return telehealth
}

export function economicSocialCost(
  pmpyUtilizationCost, //C15
  solutionImplementCost, //C13
  percentageNeedDataSubsidy, //C29
  economicBurdenPerCapita, //C34
  qualityLifeYearsPerCapita, //C36
  telehealth,
  demographic
) {
  const FixedDigitalInfrastructureCost =
    telehealth.values.FixedDigitalInfrastructureCost.value
  const addressablePopulation = demographic.values.addressablePopulation.value

  let economicCost = getMainObject("Economic & Social Cost Outputs")

  //Estimated Intervention Cost
  let formula = FixedDigitalInfrastructureCost
  formula = formula + pmpyUtilizationCost * addressablePopulation * 5
  formula = formula + solutionImplementCost
  formula =
    formula + percentageNeedDataSubsidy * addressablePopulation * 600 * 5

  economicCost.values.interventionCost = getCalculationObject(
    "Estimated Intervention Cost",
    formula
  )

  //Prospective Savings to Stakeholders
  formula = economicBurdenPerCapita * addressablePopulation * 0.5
  economicCost.values.savingsToStakeHolders = getCalculationObject(
    "Prospective Savings to Stakeholders",
    formula
  )

  //Quality AdjustedLife Years Saved
  formula = qualityLifeYearsPerCapita * addressablePopulation
  economicCost.values.qualityLifeYearsSaved = getCalculationObject(
    "QALYs Saved",
    formula,
    2,
    "years",
    false
  )

  return economicCost
}

export function stakeholderCost(economicCost) {
  const hospitalPercentage = process.env.REACT_APP_CALC_HOSPITALS //Calculations.D17
  const payersPercentage = process.env.REACT_APP_CALC_PAYERS //Calculations.D19
  const governmentPercentage = process.env.REACT_APP_CALC_GOVERNMENT //Calculations.D21
  const corporatePercentage = process.env.REACT_APP_CALC_CORPORATE //Calculations.D23

  const interventionCost = economicCost.values.interventionCost.value

  let stakeholderCost = getMainObject("Stakeholder Cost")

  let formula = interventionCost * hospitalPercentage //=$G$5*Calculations!D17
  stakeholderCost.values.hospitals = getCalculationObject("Hospitals", formula)

  formula = interventionCost * payersPercentage //=$G$5*Calculations!D19
  stakeholderCost.values.payers = getCalculationObject("Payers", formula)

  formula = interventionCost * governmentPercentage //=$G$5*Calculations!D21
  stakeholderCost.values.government = getCalculationObject(
    "Government",
    formula
  )

  formula = interventionCost * corporatePercentage //=$G$5*Calculations!D23
  stakeholderCost.values.corporate = getCalculationObject("Corporate", formula)

  return stakeholderCost
}

export function stakeHolderSavings(economicCost) {
  const hospitalPercentage = process.env.REACT_APP_CALC_HOSPITALS //Calculations.D17
  const payersPercentage = process.env.REACT_APP_CALC_PAYERS //Calculations.D19
  const governmentPercentage = process.env.REACT_APP_CALC_GOVERNMENT //Calculations.D21
  const corporatePercentage = process.env.REACT_APP_CALC_CORPORATE //Calculations.D23

  const stakeholderSavingsValue =
    economicCost.values.savingsToStakeHolders.value

  let stakeholderSavings = getMainObject("Stakeholder Savings")

  let formula = stakeholderSavingsValue * hospitalPercentage //=$G$7*Calculations!D17
  stakeholderSavings.values.hospitals = getCalculationObject(
    "Hospitals",
    formula
  )

  formula = stakeholderSavingsValue * payersPercentage //=$G$7*Calculations!D19
  stakeholderSavings.values.payers = getCalculationObject("Payers", formula)

  formula = stakeholderSavingsValue * governmentPercentage //=$G$7*Calculations!D21
  stakeholderSavings.values.government = getCalculationObject(
    "Government",
    formula
  )

  formula = stakeholderSavingsValue * corporatePercentage //=$G$7*Calculations!D23
  stakeholderSavings.values.corporate = getCalculationObject(
    "Corporate",
    formula
  )

  return stakeholderSavings
}

export function stakeHolderQualityLifeYearsSaved(economicCost) {
  const hospitalPercentage = process.env.REACT_APP_CALC_HOSPITALS //Calculations.D17
  const payersPercentage = process.env.REACT_APP_CALC_PAYERS //Calculations.D19
  const governmentPercentage = process.env.REACT_APP_CALC_GOVERNMENT //Calculations.D21
  const corporatePercentage = process.env.REACT_APP_CALC_CORPORATE //Calculations.D23

  const stakeHolderLifeYearsSaved =
    economicCost.values.qualityLifeYearsSaved.value

  let stakeholderSavings = getMainObject("Stakeholder QALY Saved")

  let formula = stakeHolderLifeYearsSaved * hospitalPercentage //=$G$7*Calculations!D17
  stakeholderSavings.values.hospitals = getCalculationObject(
    "Hospitals",
    formula,
    2,
    "years",
    false
  )

  formula = stakeHolderLifeYearsSaved * payersPercentage //=$G$7*Calculations!D19
  stakeholderSavings.values.payers = getCalculationObject(
    "Payers",
    formula,
    2,
    "years",
    false
  )

  formula = stakeHolderLifeYearsSaved * governmentPercentage //=$G$7*Calculations!D21
  stakeholderSavings.values.government = getCalculationObject(
    "Government",
    formula,
    2,
    "years",
    false
  )

  formula = stakeHolderLifeYearsSaved * corporatePercentage //=$G$7*Calculations!D23
  stakeholderSavings.values.corporate = getCalculationObject(
    "Corporate",
    formula,
    2,
    "years",
    false
  )

  return stakeholderSavings
}

export function esvaEstimates(
  economicBurdenPerCapita,
  qualityLifeYearsPerCapita
) {
  let estimates = getMainObject("ESVA Estimates")
  estimates.values.estimatedEconomicBurden = getInputObject(
    "Estimated Economic Burden per Capita",
    economicBurdenPerCapita
  )
  estimates.values.qualityAdjustedLifeYears = getInputObject(
    "Quality Adjusted Life Years per Capita",
    qualityLifeYearsPerCapita,
    ""
  )

  return estimates
}
