export function getDisplayValue(
  value,
  valueUnit,
  displayPosition,
  decimalPrecision
) {
  try {
    const prefix =
      !!displayPosition && displayPosition === "front" ? valueUnit : ""
    const suffix =
      !displayPosition || (!!displayPosition && displayPosition === "back")
        ? valueUnit
        : ""

    const checkHasValue = (value) => {
      return !!value || value === 0
    }

    if (valueUnit === "bool") {
      if (value === 1 || value === "1.0") {
        return "Yes"
      } else if (value === 0 || value === "0.0") {
        return "No"
      } else {
        return "Not available"
      }
    } else {
      let returnValue = value

      if (!isNaN(returnValue)) {
        returnValue = (+returnValue).toFixed(
          decimalPrecision !== undefined ? decimalPrecision : 2
        )

        returnValue = (+returnValue).toLocaleString(undefined, {
          minimumFractionDigits:
            decimalPrecision !== undefined ? decimalPrecision : 2,
        })
      }

      return !checkHasValue(value)
        ? "Not available"
        : `${prefix}${returnValue}${suffix}`
    }
  } catch (error) {
    console.error(error)
    return "error"
  }
}
