//Styles
import styles from "../../styles/default/calculator.module.css"

//PrimeReact
import { Card } from "primereact/card"

//Charting
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Bar,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Legend,
} from "recharts"

export default function CalculatorChart({
  stakeholderCost,
  stakeholderSavings,
  stakeHolderQualityLifeYearsSaved,
}) {
  const areaChartData = [
    {
      name: "Hospitals",
      Cost: stakeholderCost?.values.hospitals.value,
      Savings: stakeholderSavings?.values.hospitals.value,
      qaly: stakeHolderQualityLifeYearsSaved?.values.hospitals.value,
    },
    {
      name: "Payers",
      Cost: stakeholderCost?.values.payers.value,
      Savings: stakeholderSavings?.values.payers.value,
      qaly: stakeHolderQualityLifeYearsSaved?.values.payers.value,
    },
    {
      name: "Government",
      Cost: stakeholderCost?.values.government.value,
      Savings: stakeholderSavings?.values.government.value,
      qaly: stakeHolderQualityLifeYearsSaved?.values.government.value,
    },
    {
      name: "Corporate",
      Cost: stakeholderCost?.values.corporate.value,
      Savings: stakeholderSavings?.values.corporate.value,
      qaly: stakeHolderQualityLifeYearsSaved?.values.corporate.value,
    },
  ]

  const GetYAxisTick = (item) => {
    const { x, y, stroke, payload } = item

    let displayValue = (+payload.value).toFixed(2)
    displayValue = `$${(+displayValue).toLocaleString()}`

    return (
      <text
        x={x + 45}
        y={y + 4}
        fill={"#000000"}
        textAnchor="middle"
        stroke={stroke}
        fontSize={15}
      >
        {displayValue}
      </text>
    )
  }

  const GetYAxisYearsTick = (item) => {
    const { x, y, stroke, payload } = item

    let displayValue = (+payload.value).toFixed(2)
    displayValue = `${(+displayValue).toLocaleString()}`

    return (
      <text
        x={x - 30}
        y={y + 5}
        fill={"#000000"}
        stroke={stroke}
        fontSize={15}
        textAnchor="middle"
      >
        {displayValue}
      </text>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.chartToolTip}>
          <p className={styles.chartToolTipHeader}>{`${label}`}</p>
          {payload.map((x) => {
            let displayName = x.dataKey
            let displayValue = (+x.value).toFixed(2)
            displayValue = `${(+x.value).toLocaleString()}`

            if (x.dataKey === "qaly") {
              displayValue = `${displayValue} years`
              displayName = "QALY"
            } else {
              displayValue = `$${displayValue}`
            }

            return (
              <p
                className={styles.chartToolTipDetail}
                style={{ color: x.stroke }}
              >{`${displayName}: ${displayValue}`}</p>
            )
          })}
        </div>
      )
    }
  }

  return (
    <Card className={styles.chartCard}>
      <div className={styles.chartContainer}>
        <ResponsiveContainer height={300}>
          <ComposedChart
            data={areaChartData}
            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FFB077" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FFB077" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorSave" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FCC249" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FCC249" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis
              orientation="right"
              yAxisId={"bar"}
              width={120}
              tick={GetYAxisTick}
            >
              <Label
                value="Cost & Savings"
                angle={90}
                position="insideRight"
                offset={0}
                style={{ textAnchor: "middle", fontFamily: "sans-serif" }}
              />
            </YAxis>
            <YAxis
              orientation="left"
              yAxisId={"line"}
              width={100}
              tick={GetYAxisYearsTick}
            >
              <Label
                value="Quality adjusted life years saved"
                angle={90}
                position="insideLeft"
                offset={5}
                style={{ textAnchor: "middle", fontFamily: "sans-serif" }}
              />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" height={36} />
            <Bar
              name="Stakeholder Cost"
              type="monotone"
              dataKey="Cost"
              stroke="#F47E14"
              fillOpacity={1}
              fill="url(#colorUv)"
              yAxisId={"bar"}
            />
            <Bar
              name="Stakeholder Savings"
              type="monotone"
              dataKey="Savings"
              stroke="#FCC249"
              fillOpacity={1}
              fill="url(#colorSave)"
              yAxisId={"bar"}
            />
            <Line
              name="Quality-Adjusted Life Years (QALY)"
              type="monotone"
              dataKey="qaly"
              stroke="#9FBF60"
              yAxisId={"line"}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Card>
  )
}
