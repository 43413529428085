import Papa from "papaparse"
import CSVData from "../assets/data/ata_internet-access_dataset_20230319.csv"

export default function getZipData(onDataRetrieved) {
  return fetch(CSVData)
    .then((data) => data.text())
    .then((responseText) => {
      Papa.parse(responseText, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          result.data.forEach(
            (entry) => (entry.zip_code = entry.zip_code.padStart(5, "0"))
          )

          onDataRetrieved(result)
        },
      })
    })
}
