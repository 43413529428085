import { useContext } from "react"

// Context
import { MainContext } from "../../context/mainContext"

//Style
import myStyles from "../../styles/default/ataMapLegend.module.css"

//Data
import fieldOptions from "../../assets/data/variableMap.json"

//Helpers
import { getDisplayValue } from "../../helpers/zipDataDisplayHelper"

export default function AtaMapLegend({ choroplethObject }) {
  const { selectedDataField } = useContext(MainContext)

  if (!!selectedDataField && !!choroplethObject) {
    const categoryFilter = fieldOptions.filter(
      (x) => x.fieldName === selectedDataField
    )
    const category = categoryFilter.length > 0 ? categoryFilter[0] : {}

    const getCustomLegend = (choroplethObject) => {
      if (!choroplethObject || choroplethObject.bins.length === 0) {
        return null
      }

      //Calculate the color for a given value and return a block with that color.
      // If the block is at the beginning, middle or end of the range, display the value as well
      const getBin = (value, index, binCount) => {
        const col = choroplethObject.colorScale(value[0])

        //Determine what the start and end value is of the bin range.
        let firstValue = value[0]
        let secondValue = value[1]
        //If there is only one value, always use the first value
        if (value.length === 1) {
          secondValue = firstValue
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }} key={index}>
            {index === 0 ? (
              <div className={myStyles.legendValueFirst}>
                {getDisplayValue(
                  firstValue,
                  category.valueUnit,
                  category.valueUnitPosition,
                  category.decimalPrecision
                )}
              </div>
            ) : null}
            <div style={{ height: "20px" }}>
              <span
                className={myStyles.legendBox}
                style={{ backgroundColor: col.hex() }}
              ></span>
            </div>
            {index === binCount - 1 ? (
              <div className={myStyles.legendValueLast}>
                {getDisplayValue(
                  secondValue,
                  category.valueUnit,
                  category.valueUnitPosition,
                  category.decimalPrecision
                )}
              </div>
            ) : null}
          </div>
        )
      }

      if (category.valueUnit !== "bool") {
        const bins = JSON.parse(JSON.stringify(choroplethObject.bins))

        return <>{bins.map((x, index) => getBin(x, index, bins.length))}</>
      } else {
        const bins = [[1], [0]]
        return <>{bins.map((x, index) => getBin(x, index, bins.length))}</>
      }
    }

    return (
      <div className={myStyles.mainContainer}>
        <div className={myStyles.legendItemDetail}>
          {" "}
          {category.valueUnitDescription}
        </div>
        <div className={myStyles.legendItemLegend}>
          {getCustomLegend(choroplethObject)}
        </div>
      </div>
    )
  } else {
    return null
  }
}
